<template>
  <div class="announcement">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">公告</div>
      <div class="rightBtn"></div>
    </div>
    <div class="content">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isNoData="isNoData"
        :error="error"
        :isHigehtMax="true"
      >
        <div
          class="item"
          v-for="item in list"
          :key="item.id"
          @click="
            $router.push({
              name: 'AnnounDetails',
              params: {
                data: item,
              },
            })
          "
        >
          <div class="cover">
            <ImgDecypt :src="item.cover" />
          </div>
          <div class="info">
            <div class="title ellipsis">{{ item.title }}</div>
            <!-- <div class="enter">
              <span>立即进入</span>
              <svg-icon icon-class="rught" />
            </div> -->
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import ImgDecypt from '@/components/ImgDecypt';
import { getAnnouList } from '@/api/information';
import PullRefresh from '@/components/PullRefresh';

export default {
  name: 'announcement',
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      list: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      try {
        let res = await this.$Api(getAnnouList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.pageNumber == 1 && this.list.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
  },
};
</script>
<style lang="scss" scoped>
.announcement {
  height: 100%;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .content {
    padding: 0 16px;
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .item {
      padding-top: 12px;
      .cover {
        height: 184px;
        border-radius: 8px;
        overflow: hidden;
      }

      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        font-size: 16px;
        margin-bottom: 6px;

        .enter {
          display: flex;
          align-items: center;
          flex-shrink: 0;

          span {
            font-size: 14px;
            color: rgb(102, 102, 102);
          }

          > svg {
            font-size: 26px;
          }
        }
      }
    }
  }
}
</style>
